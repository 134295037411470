//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getProjects } from '@/api/adConfig';
import { formatTimestamp } from '@/utils/date';
import infoList from '@/mixins/infoList';
export default {
  name: 'AdConfig',
  filters: {
    timestampFormat: function timestampFormat(time) {
      return formatTimestamp(time);
    }
  },
  mixins: [infoList],
  data: function data() {
    return {
      listApi: getProjects
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    addConfigInfo: function addConfigInfo() {
      this.$router.push({
        name: 'configInfo'
      });
    },
    toProject: function toProject(row) {
      this.$router.push({
        name: 'project',
        query: {
          project: row.projectName
        }
      });
    },
    toApp: function toApp(row) {
      this.$router.push({
        name: 'appEnvs',
        query: {
          project: row.projectName
        }
      });
    }
  }
};